<script setup lang="ts">
import { DotLoader } from '@wision/ui';
import DashboardGrid from '../../features/dashboard/DashboardGrid.vue';
import { onMounted } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import 'vue3-grid-layout-next/dist/style.css';
import '@/features/dashboard/styles.css';
import { withUseQuery } from '@wision/api';

definePageMeta({
  title: 'Dashboard',
});

const apiClient = useApi();
const route = useRoute();
const { $supabase } = useNuxtApp();

const dashboardId = route.params.dashboard as string;
const { data: user } = await $supabase.auth.getUser();
if (!user.user?.id) {
  navigateTo('/login');
}
const { data, error } = await $supabase.rpc('get_user_access', {
  user_id: user.user?.id ?? '',
});
const { data: currentOrganizationId } = await $supabase.rpc('get_current_organization');
const currentOrganization = data?.find((d) => d.id === currentOrganizationId);

if (data === null || error) {
  navigateTo('/');
}
const [dashboard, dashboardError] = await apiClient.dashboard.get(
  dashboardId ?? ''
);

if (currentOrganization?.xtool_id != dashboard.organizationId) {
  navigateTo('/');
}
const [widgets, widgetError] = await apiClient.dashboard.getWidgets();

onMounted(() => {
  const queryClient = useQueryClient();
  queryClient.ensureQueryData({
    queryKey: ['deviceFilter', dashboard.filter],
    queryFn: () =>
      withUseQuery(() =>
        apiClient.device.getDeviceFilter(true, dashboard.filter)
      ),
  });
});

</script>

<template>
  <div>
    <NuxtLayout
      name="layout"
      :title="`${$t('pages.maintitle')} | ${
        dashboard.content.name.includes('nav')
          ? $t(dashboard.content.name)
          : dashboard.content.name
      }`"
      :error="dashboardError || widgetError"
      :header-title="
        dashboard.content.name.includes('nav')
          ? $t(dashboard.content.name)
          : dashboard.content.name
      "
    >
      <ClientOnly fallback-tag="div">
        <DashboardGrid
          v-if="!(dashboardError || widgetError)"
          :dashboard-id="dashboardId ?? ''"
          :available-widgets="widgets"
          :dashboard="dashboard"
        />
        <template #fallback>
          <div class="w-full h-full flex items-center justify-center">
            <DotLoader color="white" />
          </div>
        </template>
      </ClientOnly>
    </NuxtLayout>
  </div>
</template>
